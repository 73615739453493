<template>
  <div>
    <b-button
      pill
      v-b-tooltip.bottom="messages.getActionRemoveUser()"
      @click="$bvModal.show('remove-' + user.userID)"
    >
      <i
        class="icon-trash"
      />
    </b-button>
    <b-modal
      :id="'remove-' + user.userID"
      hide-header
      hide-footer
      centered
    >
      <b-card-title
        class="text-center mb-4"
      >
        {{ messages.getLabelUserRemovalConfirmation({ email: user.userEmail }) }}
      </b-card-title>
      <div
        class="d-flex justify-content-around"
      >
        <b-button
          variant="danger"
          class="text-uppercase"
          @click="removeUser"
        >
          <span
            v-show="!loading"
          >
            {{ messages.getActionRemove() }}
          </span>
          <b-spinner
            small
            v-show="loading"
          />
        </b-button>
        <b-button
          class="text-uppercase"
          @click="$bvModal.hide('remove-' + user.userID)"
        >
          {{ messages.getActionClose() }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { messages } from '@/utils/strings'

export default {
  name: 'RemoveUserCell',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      messages
    }
  },
  methods: {
    async removeUser () {
      this.loading = true
      const url = (
        process.env.VUE_APP_USER_API_ENDPOINT +
        `/user_api/v1/users/${this.user.userID}`
      )
      try {
        await axios.delete(url)
        this.$emit('remove', this.user)
      } catch (error) {
        this.$store.commit('setErrorMessage', error.response.data.message)
      } finally {
        this.loading = false
        this.$bvModal.hide('remove-' + this.user.userID)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.icon-trash {
  font-size: 20px;
}
</style>
