<template>
  <page-layout
    class="pt-5"
  >
    <users-list/>
    <pending-approvals-list
      v-show="shouldShowPending"
    />
  </page-layout>
</template>

<script>
import PageLayout from '../components/PageLayout'
import UsersList from '../components/UsersManagement/UsersList'
import PendingApprovalsList from '../components/UsersManagement/PendingApprovalsList'
import { userManagement } from '@/constants/permitted-actions'
import { SUPER_MANAGER, UM_USER_ACCEPTANCE } from '@/constants/permissions'

export default {
  name: 'UsersManagement',
  components: { PendingApprovalsList, UsersList, PageLayout },
  data () {
    return {
      approvalsLoading: true,
      shouldShowPending: false
    }
  },
  mounted () {
    if (!this.$store.getters.isPermitted(userManagement.get)) {
      console.warn('User does not have permissions to access /users-management')
      this.$router.push('/status-monitor')
    }
    if ((this.$store.getters.hasCurrentUserSomeOfPermissions([SUPER_MANAGER, UM_USER_ACCEPTANCE]))) {
      this.shouldShowPending = true
      this.$store.dispatch('getPendingApprovals')
        .finally(() => {
          this.approvalsLoading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
