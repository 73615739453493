/**
 * Validation states that form components can have.
 *
 * The general UX guideline for form field validation states is:
 *
 * - Text field with no color (`NEUTRAL`) means that...
 *   - the user hasn't focused-out of it yet, or...
 *   - the user hasn't tried to submit the form yet, or...
 *   - the field is valid.
 * - Text field with a red color (`INVALID`) means that it's invalid.
 * - Text field with a green/blue color (`VALID`) means that the field is valid.
 *   - (Only used with complex validation, like password validation, or when a
 *     large computation or backend call is needed.)
 *
 * The values (`null`, `false`, and `true`) come from Vue-Bootstrap.
 *
 * @see <https://www.nngroup.com/articles/errors-forms-design-guidelines/>
 * @see <https://bootstrap-vue.org/docs/components/form#validation>
 * @see <https://verkkoaps.atlassian.net/wiki/spaces/CPLTD/pages/161284097/UI+UX+Guidelines>
 */

export const NEUTRAL = null
export const VALID = true
export const INVALID = false
