import { render, staticRenderFns } from "./RemoveUserCell.vue?vue&type=template&id=5aa99837&scoped=true&"
import script from "./RemoveUserCell.vue?vue&type=script&lang=js&"
export * from "./RemoveUserCell.vue?vue&type=script&lang=js&"
import style0 from "./RemoveUserCell.vue?vue&type=style&index=0&id=5aa99837&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa99837",
  null
  
)

export default component.exports