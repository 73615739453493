<template>
  <b-col
    md="6"
    lg="5"
    xl="4"
  >
    <b-card
    >
      <b-card-title
        class="text-primary"
      >
        {{ messages.getLabelPendingApprovals() }}
      </b-card-title>
      <b-table
        :items="pendingApprovals"
        :fields="fields"
        thead-class="d-none"
        table-class="border-bottom"
        show-empty
        :empty-text="noDataMessage"
      >
        <template
          v-slot:cell(icon)
        >
          <b-avatar
            size="3rem"
          >
            <i
              class="icon-user"
            />
          </b-avatar>
        </template>
        <template
          v-slot:cell(button)="data"
        >
          <b-button
            @click="goToRequest(data.item.requestId)"
            class="text-uppercase"
            variant="light"
          >
            {{ messages.getActionGoToRequest() }}
            <i
              class="icon-arrow-right"
            />
          </b-button>
        </template>
      </b-table>
    </b-card>
  </b-col>
</template>

<script>
import { messages } from '@/utils/strings'

export default {
  name: 'PendingApprovalsList',
  data () {
    return {
      fields: ['icon', 'requestingUserEmail', 'button'],
      messages
    }
  },
  computed: {
    pendingApprovals () {
      return this.$store.state.pendingApprovals
    },
    noDataMessage () {
      if (this.loading) {
        return messages.getLabelFetchingData()
      }
      return messages.getLabelEmptyPendingApprovals()
    }
  },
  methods: {
    goToRequest (requestID) {
      this.$store.commit('setApprovalRequest', {})
      this.$router.push(`/approval-request/${requestID}`)
    }
  }
}
</script>

<style scoped>
.icon-user {
  font-size: 20px;
}
</style>
