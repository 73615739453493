<template>
  <div
    class="d-flex align-items-center"
  >
    <b-button
      v-if="isPermitted(userManagement.user.permissions.get)"
      variant="link"
      class="text-decoration-none"
      v-b-tooltip.bottom="messages.getTooltipEditPermissions()"
      @click="setUserToEditPermissions()"
    >
      <i
        class="icon-edit text-primary"
      />
    </b-button>
    <div>
      <template
        v-for="(permissionId, key) in user.permissionIds"
      >
        <b-badge
          v-if="getPermissionLabel(permissionId)"
          :key="key"
          pill
          class="font-weight-normal badge my-1"
          :id="user.userID + '-permission-' + key"
        >
          {{ getPermissionLabel(permissionId) }}
        </b-badge>
      </template>
    </div>
    <b-modal
      :id="'permissions-' + user.userID"
      header-bg-variant="primary"
      header-text-variant="white"
      hide-footer
      centered
      scrollable
    >
      <template
        v-slot:modal-header
      >
        <div>{{ messages.getLabelEditPermissionsForUser({ userEmail: user.userEmail }) }}</div>
      </template>
      <b-card-title
        v-if="visiblePermissions.length > 0"
        class="text-center"
      >
        {{ messages.getFieldAvailablePermissions() }}:
      </b-card-title>
      <b-row
        cols="2"
        no-gutters
      >
        <b-col
          v-for="(item, key) in visiblePermissions"
          :key="key"
          class="py-2 px-3"
        >
          <div
            :id="`${user.userID}-${item.id}`"
          >
            <b-form-checkbox
              v-model="selectedPermissionIds"
              :value="item.id"
              :disabled="isPermissionCheckboxDisabled(item)"
            >
              {{ item.label }}
            </b-form-checkbox>
          </div>
          <b-tooltip
            :target="`${user.userID}-${item.id}`"
            :title="getPermissionDescription(item.id)"
            triggers="hover"
            placement="topleft"
          />
        </b-col>
      </b-row>
      <div
        v-if="
          isPermitted(userManagement.user.permissions.shipToPartyNumbers.get) &&
          isPermitted(userManagement.user.permissions.shipToPartyNumbers.have, selectedPermissionIds)
        "
        class="pt-2"
      >
        <div
          class="px-3 pb-2"
        >
          {{ messages.getLabelEditUserProfileShipToPartyNumber() }}:
        </div>
        <searchable-table-tags
          v-model="selectedShipToPartyNumbers"
          :unselected="unselectedShipToPartyNumbers"
          :emptyText="noDataShipToPartyNumbers"
          :disabled="
            !isPermitted(userManagement.user.permissions.shipToPartyNumbers.update)
          "
          placeholder="Add ship to party number"
        />
      </div>
      <autocomplete-tags
        v-if="
          isPermitted(userManagement.user.permissions.plants.get) &&
          isPermitted(userManagement.user.permissions.plants.have, selectedPermissionIds)
        "
        :disabled="
          !isPermitted(userManagement.user.permissions.plants.update)
        "
        input-id="plants-field"
        datalist-id="plants-field-datalist"
        v-model="plants"
        :suggestions="queriedPlants"
        @field-input="onPlantsFieldInput"
      >
        {{ messages.getLabelEditUserProfilePlant() }}
      </autocomplete-tags>
      <autocomplete-tags
        v-if="
          isPermitted(userManagement.user.permissions.workCenters.get) &&
          isPermitted(userManagement.user.permissions.workCenters.have, selectedPermissionIds)
        "
        :disabled="
          !isPermitted(userManagement.user.permissions.workCenters.update)
        "
        input-id="workCenters-field"
        datalist-id="workCenters-field-datalist"
        v-model="workCenters"
        @field-input="onWorkCentersFieldInput"
      >
        {{ messages.getLabelEditUserProfileWorkCenter() }}
      </autocomplete-tags>
      <autocomplete-tags
        v-if="
          isPermitted(userManagement.user.permissions.plannerGroups.get) &&
          isPermitted(userManagement.user.permissions.plannerGroups.have, selectedPermissionIds)
        "
        :disabled="
          !isPermitted(userManagement.user.permissions.plannerGroups.update)
        "
        input-id="planner-groups-field"
        datalist-id="planner-groups-field-datalist"
        v-model="plannerGroups"
        :suggestions="queriedPlannerGroups"
        @field-input="onPlannerGroupsFieldInput"
      >
        {{ messages.getLabelEditUserProfilePlannerGroup() }}
      </autocomplete-tags>
      <autocomplete-tags
        v-if="
          isPermitted(userManagement.user.permissions.storageLocations.get)
        "
        :disabled="
          !isPermitted(userManagement.user.permissions.storageLocations.update)
        "
        input-id="storage-locations-field"
        datalist-id="storage-locations-field-datalist"
        v-model="storageLocations"
        :suggestions="queriedStorageLocations"
        @field-input="onStorageLocationsFieldInput"
      >
        {{ messages.getLabelEditUserProfileStorageLocation() }}
      </autocomplete-tags>
      <div
        class="d-flex justify-content-around pt-3"
      >
        <b-button
          v-if="isPermitted(userManagement.user.permissions.update)"
          variant="primary"
          @click="editUserPermissions"
        >
          <span
            v-show="!loading"
          >
            {{ messages.getActionSave() }}
          </span>
          <b-spinner
            small
            v-show="loading"
          />
        </b-button>
        <b-button
          @click="$bvModal.hide('permissions-' + user.userID)"
        >
          {{ messages.getActionClose() }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { messages } from '@/utils/strings'
import {
  CAMEL_CASED_PERMISSION_NAMES,
  KONE_ROLES
} from '@/constants/permissions'
import { userManagement } from '@/constants/permitted-actions'
import AutocompleteTags from './AutocompleteTags'
import SearchableTableTags from './SearchableTableTags'

export default {
  name: 'PermissionsCell',
  components: {
    AutocompleteTags,
    SearchableTableTags
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    availableShipToParties: {
      type: Array,
      required: true
    },
    noDataShipToPartyNumbers: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      selectedPermissionIds: [],
      selectedShipToPartyNumbers: [],
      plants: [],
      plantsQuery: '',
      queriedPlants: [],
      prefetchedPlants: [],
      plannerGroupsQuery: '',
      plannerGroups: [],
      queriedPlannerGroups: [],
      prefetchedPlannerGroups: [],
      storageLocationsQuery: '',
      storageLocations: [],
      queriedStorageLocations: [],
      prefetchedStorageLocations: [],
      workCenters: [],
      workCentersQuery: '',
      messages
    }
  },
  computed: {
    allPermissions () {
      return Object.values(this.$store.state.constants.permissions)
    },
    grantablePermissions () {
      return this.allPermissions.filter(permission =>
        this.isPermitted(
          'userManagement.user.permissions.' +
          CAMEL_CASED_PERMISSION_NAMES[permission.id] +
          '.update'
        )
      )
    },
    visiblePermissions () {
      return this.allPermissions.filter(permission =>
        this.isPermitted(
          'userManagement.user.permissions.' +
          CAMEL_CASED_PERMISSION_NAMES[permission.id] +
          '.get'
        )
      )
    },
    unselectedShipToPartyNumbers () {
      return this.availableShipToParties.filter(
        party => !this.selectedShipToPartyNumbers.includes(party.code)
      )
    },
    isSomeKoneRoleSelected () {
      return KONE_ROLES.some(
        koneRole => this.selectedPermissionIds.includes(koneRole)
      )
    }
  },
  created () {
    this.userManagement = userManagement
  },
  methods: {
    isPermitted (permittedAction, permissionIds) {
      return this.$store.getters.isPermitted(permittedAction, permissionIds)
    },
    getPermissionLabel (permissions) {
      return this.$store.state.constants.permissions[permissions]?.label
    },
    getPermissionDescription (permissions) {
      return this.$store.state?.constants?.permissions[permissions]?.description
    },
    isKoneRole (permission) {
      return KONE_ROLES.includes(permission.id)
    },
    isPermissionSelected (permission) {
      return this.selectedPermissionIds.includes(permission.id)
    },
    /**
     * Returns whether permission checkbox is disabled, based on requirements:
     * - If the user cannot edit users or grant the permission, the checkbox is
     *   always disabled.
     * - Otherwise:
     *   - If no KONE role is selected, user can select any one of them.
     *   - If one or more KONE roles are selected, user can only unselect them.
     *     - (Getting more than one KONE role needs to be possible through
     *       backend, but not frontend.)
     *
     * See: <https://verkkoaps.atlassian.net/browse/CPL-375>
     */
    isPermissionCheckboxDisabled (permission) {
      return (
        !this.isPermitted(userManagement.user.permissions.update) ||
        !this.grantablePermissions.includes(permission) ||
        (
          this.isKoneRole(permission) &&
          !this.isPermissionSelected(permission) &&
          this.isSomeKoneRoleSelected
        )
      )
    },
    setUserToEditPermissions () {
      if (this.user.permissionIds) {
        this.selectedPermissionIds = this.user.permissionIds
      } else {
        this.selectedPermissionIds = []
      }
      if (this.user.workCenters) {
        this.workCenters = this.user.workCenters.slice()
      } else {
        this.workCenters = []
      }
      if (this.user.managedShipToPartyNumbers) {
        this.selectedShipToPartyNumbers = this.user.managedShipToPartyNumbers.slice()
      } else {
        this.selectedShipToPartyNumbers = []
      }
      if (this.user.plants) {
        this.plants = this.user.plants.slice()
      } else {
        this.plants = []
      }
      if (this.user.plannerGroups) {
        this.plannerGroups = this.user.plannerGroups.slice()
      } else {
        this.plannerGroups = []
      }
      if (this.user.storageLocations) {
        this.storageLocations = this.user.storageLocations.slice()
      } else {
        this.storageLocations = []
      }
      this.queryPlants('')
      this.queryPlannerGroups('')
      this.queryStorageLocations('')
      this.$bvModal.show('permissions-' + this.user.userID)
    },
    async editUserPermissions () {
      this.loading = true
      const url = (
        process.env.VUE_APP_USER_API_ENDPOINT +
        `/user_api/v1/users/${this.user.userID}/permissions`
      )
      try {
        await axios.put(url, {
          permissions: this.selectedPermissionIds,
          managed_ship_to_party_numbers: this.selectedShipToPartyNumbers,
          plants: this.plants,
          planner_groups: this.plannerGroups,
          storage_locations: this.storageLocations,
          work_centers: this.workCenters
        })
        this.user.permissionIds = this.selectedPermissionIds
        this.user.managedShipToPartyNumbers = this.selectedShipToPartyNumbers
        this.user.plants = this.plants
        this.user.plannerGroups = this.plannerGroups
        this.user.storageLocations = this.storageLocations
        this.user.workCenters = this.workCenters
        this.$bvModal.hide('permissions-' + this.user.userID)
      } catch (error) {
        this.$store.commit('setErrorMessage', error.response.data.message)
      } finally {
        this.loading = false
      }
    },
    queryPrefetchedPlants () {
      this.queriedPlants = this.prefetchedPlants.filter(plant => (
        !this.plants.includes(plant)
      ))
    },
    async queryPlants () {
      if (!this.isPermitted(userManagement.user.permissions.plants.update)) {
        return
      }
      this.queryPrefetchedPlants()
      const url = (
        process.env.VUE_APP_USER_API_ENDPOINT +
        '/user_api/v1/plants'
      )
      try {
        const response = await axios.get(url, {
          params: {
            query: this.plantsQuery
          }
        })
        this.prefetchedPlants = response.data.plants.map(element => element.plant)
        this.queryPrefetchedPlants()
      } catch (error) {
        this.$store.commit('setErrorMessage', error.response.data.message)
      }
    },
    queryPrefetchedPlannerGroups () {
      this.queriedPlannerGroups = this.prefetchedPlannerGroups.filter(
        plannerGroup => !this.plannerGroups.includes(plannerGroup)
      )
    },
    async queryPlannerGroups () {
      if (!this.isPermitted(userManagement.user.permissions.plannerGroups.update)) {
        return
      }
      this.queryPrefetchedPlannerGroups()
      const url = (
        process.env.VUE_APP_USER_API_ENDPOINT +
        '/user_api/v1/planner_groups'
      )
      try {
        const response = await axios.get(url, {
          params: {
            query: this.plannerGroupsQuery
          }
        })
        this.prefetchedPlannerGroups = response.data.planner_groups
          .map(element => element.planner_group)
        this.queryPrefetchedPlannerGroups()
      } catch (error) {
        this.$store.commit('setErrorMessage', error.response.data.message)
      }
    },
    queryPrefetchedStorageLocations () {
      this.queriedStorageLocations = this.prefetchedStorageLocations.filter(
        storageLocation => !this.storageLocations.includes(storageLocation)
      )
    },
    async queryStorageLocations () {
      if (!this.isPermitted(userManagement.user.permissions.storageLocations.update)) {
        return
      }
      this.queryPrefetchedStorageLocations()
      const url = (
        process.env.VUE_APP_USER_API_ENDPOINT +
        '/user_api/v1/storage_locations'
      )
      try {
        const response = await axios.get(url, {
          params: {
            query: this.storageLocationsQuery
          }
        })
        this.prefetchedStorageLocations = response.data.storage_locations
          .map(element => element.storage_location)
        this.queryPrefetchedStorageLocations()
      } catch (error) {
        this.$store.commit('setErrorMessage', error.response.data.message)
      }
    },
    onPlantsFieldInput (value) {
      this.plantsQuery = value.trim()
    },
    onPlannerGroupsFieldInput (value) {
      this.plannerGroupsQuery = value.trim()
    },
    onStorageLocationsFieldInput (value) {
      this.storageLocationsQuery = value.trim()
    },
    onWorkCentersFieldInput (value) {
      this.workCentersQuery = value.trim()
    }
  },
  /**
   * Watchers for the subcomponent values:
   *
   * - `plants`, `plannerGroups`, and `storageLocations` change when the user
   *   adds or removes a tag, in order to update the list of suggested tags to
   *   include or exclude the operated tag.
   *
   * - `plantsQuery`, `plannerGroupsQuery`, and `storageLocationsQuery` change
   *   when the user types a new letter to the text field (or presses
   *   backspace, copypastes, or so on...), in order to update the list of
   *   suggested tags from backend.
   */
  watch: {
    plants () {
      this.queryPrefetchedPlants()
    },
    plantsQuery () {
      this.queryPlants()
    },
    plannerGroups () {
      this.queryPrefetchedPlannerGroups()
    },
    plannerGroupsQuery () {
      this.queryPlannerGroups()
    },
    storageLocations () {
      this.queryPrefetchedStorageLocations()
    },
    storageLocationsQuery () {
      this.queryStorageLocations()
    }
  }
}
</script>

<style scoped lang="scss">
.badge {
  font-size: initial;
}
</style>
