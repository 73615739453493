<template>
  <b-form-tags
    v-bind:value="value"
    v-on:input="onTagsInput"
    no-outer-focus
    class="mb-2"
    :limit="limit"
  >
    <template
      v-slot="{
        tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag
      }"
    >
      <!--
        The following component is needed, because `b-form-input` doesn't emit
        an `input` event when `b-form-tags` changes its value, which happens
        when a tag is added (which removes the tag text from `b-form-input`):
      -->
      <value-watcher
        :value="inputAttrs.value"
        @value-changed="onFieldInput"
      ></value-watcher>
      <label :for="inputId">
        <slot></slot>
      </label>
      <b-form-tag
        v-for="tag in tags"
        @remove="removeTag(tag)"
        :key="tag"
        :title="tag"
        :disabled="disabled"
        :variant="tagVariant"
        class="mb-2 mx-1"
      >{{ tag }}</b-form-tag>
      <b-input-group v-if="!disabled">
        <b-form-input
          :id="inputId"
          v-bind="inputAttrs"
          v-on="inputHandlers"
          :list="datalistId"
          autocomplete="off"
        ></b-form-input>
        <b-input-group-append>
          <b-button @click="addTag()" variant="primary">{{ messages.getActionAdd() }}</b-button>
        </b-input-group-append>
      </b-input-group>
      <datalist :id="datalistId">
        <option v-for="suggestion in suggestions" :key="suggestion">
          {{ suggestion }}
        </option>
      </datalist>
    </template>
  </b-form-tags>
</template>

<script>
import { messages } from '@/utils/strings'
import ValueWatcher from './ValueWatcher'

export default {
  name: 'AutocompleteTags',
  components: { ValueWatcher },
  props: {
    inputId: {
      type: String,
      required: true
    },
    datalistId: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    suggestions: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    updateSuggestions: {
      type: Function,
      default: () => []
    },
    limit: {
      type: Number,
      required: false
    }
  },
  methods: {
    onTagsInput (value) {
      this.$emit('input', value)
    },
    onFieldInput (value) {
      this.$emit('field-input', value)
    }
  },
  data () {
    return {
      messages
    }
  }
}
</script>

<style scoped>
.badge {
  font-size: initial;
}
</style>
