<template>
  <div
    class="d-flex"
  >
    <b-button
      v-if="isEditable"
      variant="link"
      class="text-decoration-none"
      @click="$bvModal.show('expiration-date-' + user.userID)"
      v-b-tooltip.hover.bottom
      :title="messages.getTooltipEditExpirationDate()"
    >
      <div
        class="icon-container"
      >
        <i
          class="icon-calendar text-primary"
        />
      </div>
    </b-button>
    <span
      class="pl-2"
    >
      {{ user.expires }}
    </span>
    <b-modal
      :id="'expiration-date-' + user.userID"
      header-bg-variant="primary"
      header-text-variant="white"
      hide-footer
      centered
    >
      <template
        v-slot:modal-header
      >
        <div>{{ messages.getLabelEditExpirationForUser({ userEmail: user.userEmail }) }}</div>
      </template>
      <b-calendar
        block
        hide-header
        v-model="dateString"
      />
      <div
        class="d-flex justify-content-center pt-3"
      >
        <b-button
          @click="editExpireDate"
          variant="outline-primary"
        >
          <span
            v-show="!loading"
          >
            {{ messages.getActionSaveExpirationDate() }}
          </span>
          <b-spinner
            small
            v-show="loading"
          />
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { messages } from '@/utils/strings'

export default {
  name: 'ExpirationDateCell',
  props: {
    isEditable: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      // Use user's current expiration date as default for the calendar:
      dateString: this.finnishDateStringToIsoDateString(this.user.expires),
      loading: false,
      messages
    }
  },
  methods: {
    /**
     * Takes a Finnish date string (format: `"DD.MM.YYYY"`, example:
     * `"12.04.2021"`), and returns an ISO date string (format:
     * `"YYYY-MM-DD"`, example: `"2021-04-12"`), or empty string if the Finnish
     * date string is invalid.
     *
     * Note: Format: `"DD.MM.YYYY"` is determined by the Backend and
     * format: `"YYYY-MM-DD"` is determined by `<b-calendar>`, so neither
     * should be localized.
     */
    finnishDateStringToIsoDateString (finnishDateString) {
      if (/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(finnishDateString)) {
        return finnishDateString?.split('.').reverse().join('-')
      } else {
        return ''
      }
    },
    async editExpireDate () {
      this.loading = true
      const url = (
        process.env.VUE_APP_USER_API_ENDPOINT +
        `/user_api/v1/users/${this.user.userID}/expiration`
      )
      try {
        const response = await axios.put(url, { expiration: this.dateString })
        this.user.expires = response.data.expires
        this.$bvModal.hide('expiration-date-' + this.user.userID)
      } catch (error) {
        this.$store.commit('setErrorMessage', error.response.data.message)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
$icon-font-size: 16px;
.icon-container {
  min-width: $icon-font-size;
}
.icon-calendar {
  position: relative;
  left: $icon-font-size / 2;
}
</style>
