<template>
  <b-col>
    <b-tabs v-model="currentTabIndex" content-class="mt-3">
      <b-tab
        v-if="isPermitted(userManagement.approvedUsers.list)"
        :title="messages.getPageUsersTabApprovedUsers()"
        lazy
      >
        <user-list-table
          :users="approvedUsers"
          :no-data-ship-to-party-numbers="noDataShipToPartyNumbers"
          :available-ship-to-parties="availableShipToParties"
          :has-more="hasMoreApprovedUsers"
          :is-loading="isLoadingApprovedUsers"
          @remove="onUserRemove"
          @load-more="getApprovedUsers"
        />
      </b-tab>
      <b-tab
        v-if="isPermitted(userManagement.myPlantUsers.list)"
        :title="messages.getPageUsersTabMyPlantUsers()"
        lazy
      >
        <email-search
          id="my-plant-users-email"
          @submit="onMyPlantUsersEmailSearchSubmit"
        />
        <user-list-table
          v-if="areQueriedMyPlantUsersVisible"
          :users="queriedMyPlantUsers"
          :no-data-ship-to-party-numbers="noDataShipToPartyNumbers"
          :available-ship-to-parties="availableShipToParties"
          :has-more="hasMoreQueriedMyPlantUsers"
          :is-loading="isLoadingQueriedMyPlantUsers"
          @remove="onUserRemove"
          @load-more="getQueriedMyPlantUsers"
        />
        <user-list-table
          v-else
          :users="myPlantUsers"
          :no-data-ship-to-party-numbers="noDataShipToPartyNumbers"
          :available-ship-to-parties="availableShipToParties"
          :has-more="hasMoreMyPlantUsers"
          :is-loading="isLoadingMyPlantUsers"
          @remove="onUserRemove"
          @load-more="getMyPlantUsers"
        />
      </b-tab>
      <b-tab
        v-if="isPermitted(userManagement.allUsers.list)"
        :title="messages.getPageUsersTabAllUsers()"
        lazy
      >
        <email-search
          id="all-users-email"
          @submit="onAllUsersEmailSearchSubmit"
        />
        <user-list-table
          v-if="areQueriedAllUsersVisible"
          :users="queriedAllUsers"
          :no-data-ship-to-party-numbers="noDataShipToPartyNumbers"
          :available-ship-to-parties="availableShipToParties"
          :has-more="hasMoreQueriedAllUsers"
          :is-loading="isLoadingQueriedAllUsers"
          @remove="onUserRemove"
          @load-more="getQueriedAllUsers"
        />
        <user-list-table
          v-else
          :users="allUsers"
          :no-data-ship-to-party-numbers="noDataShipToPartyNumbers"
          :available-ship-to-parties="availableShipToParties"
          :has-more="hasMoreAllUsers"
          :is-loading="isLoadingAllUsers"
          @remove="onUserRemove"
          @load-more="getAllUsers"
        />
      </b-tab>
    </b-tabs>
  </b-col>

</template>

<script>
import axios from 'axios'
import { messages } from '@/utils/strings'
import { userManagement } from '@/constants/permitted-actions'
import EmailSearch from './EmailSearch'
import UserListTable from './UserListTable'

const fetchIncrement = 20

/**
 * Set of tabs that list the users.
 *
 * Note: "My Plant Users" does not use a cursor, because it can only return
 * results from the beginning of the query; and as a result, we merely increase
 * the number of users when asked for more.
 */
export default {
  name: 'UsersList',
  components: { EmailSearch, UserListTable },
  data () {
    return {
      allUsers: [],
      allUsersCursor: '',
      hasMoreAllUsers: true,
      isLoadingAllUsers: true,

      queriedAllUsers: [],
      queriedAllUsersCursor: '',
      hasMoreQueriedAllUsers: true,
      isLoadingQueriedAllUsers: true,
      areQueriedAllUsersVisible: false,

      myPlantUsers: [],
      myPlantUsersShowAmount: 0,
      hasMoreMyPlantUsers: true,
      isLoadingMyPlantUsers: true,

      queriedMyPlantUsers: [],
      queriedMyPlantUsersShowAmount: 0,
      hasMoreQueriedMyPlantUsers: true,
      isLoadingQueriedMyPlantUsers: true,
      areQueriedMyPlantUsersVisible: false,

      approvedUsers: [],
      approvedUsersCursor: '',
      hasMoreApprovedUsers: true,
      isLoadingApprovedUsers: true,

      noDataShipToPartyNumbers: messages.getLabelFetchingData(),
      currentTabIndex: 0,
      availableShipToParties: [],

      messages
    }
  },
  created () {
    this.userManagement = userManagement
  },
  methods: {
    isPermitted (permittedAction, permissionIds) {
      return this.$store.getters.isPermitted(permittedAction, permissionIds)
    },
    onAllUsersEmailSearchSubmit (value) {
      if (value === '') {
        this.areQueriedAllUsersVisible = false
      } else {
        this.queriedAllUsers = []
        this.queriedAllUsersCursor = ''
        this.areQueriedAllUsersVisible = true
        this.getQueriedAllUsers(value)
      }
    },
    onMyPlantUsersEmailSearchSubmit (value) {
      if (value === '') {
        this.areQueriedMyPlantUsersVisible = false
      } else {
        this.queriedMyPlantUsers = []
        this.queriedMyPlantUsersShowAmount = 0
        this.areQueriedMyPlantUsersVisible = true
        this.getQueriedMyPlantUsers(value)
      }
    },
    onUserRemove (removedUser) {
      const isNotRemovedUser = user => user.userID !== removedUser.userID
      this.allUsers = this.allUsers.filter(isNotRemovedUser)
      this.approvedUsers = this.approvedUsers.filter(isNotRemovedUser)
      this.queriedAllUsers = this.queriedAllUsers.filter(isNotRemovedUser)
      this.queriedMyPlantUsers = this.queriedMyPlantUsers.filter(isNotRemovedUser)
    },
    parseBackendApprovedUsers (item) {
      return {
        userID: item.user_id,
        userEmail: item.user_email,
        lastLogin: item.last_login,
        permissionIds: item.permissions,
        managedShipToPartyNumbers: item.ship_to_party_numbers,
        plants: item.plants,
        plannerGroups: item.planner_groups,
        storageLocations: item.storage_locations,
        expires: item.expires,
        workCenters: item.work_centers
      }
    },
    parseBackendAllUsers (item) {
      return {
        userID: item.user_id,
        userEmail: item.user_email,
        lastLogin: item.last_login,
        permissionIds: item.permissions,
        expires: item.expires,
        managedShipToPartyNumbers: item.ship_to_party_numbers,
        plants: item.plants,
        plannerGroups: item.planner_groups,
        storageLocations: item.storage_locations,
        wasApprovedByCurrent: item.was_approved_by_current,
        workCenters: item.work_centers
      }
    },
    getAllUsers () {
      if (!this.isPermitted(userManagement.allUsers.list)) {
        return
      }
      this.isLoadingAllUsers = true
      const url = (
        `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/users`
      )
      axios
        .get(url, {
          params: {
            cursor: this.allUsersCursor
          }
        })
        .then((resp) => {
          this.allUsers = [
            ...this.allUsers,
            ...resp.data.users.map(this.parseBackendAllUsers)
          ]
          this.allUsersCursor = resp.data.cursor
          this.hasMoreAllUsers = Boolean(this.allUsersCursor)
          this.getAvailableShipToPartyNumbers()
        })
        .catch((error) => {
          this.$store.commit('setErrorMessage', error.response.data.message)
        })
        .finally(() => {
          this.isLoadingAllUsers = false
        })
    },
    getMyPlantUsers () {
      if (!this.isPermitted(userManagement.myPlantUsers.list)) {
        return
      }
      this.isLoadingMyPlantUsers = true
      this.myPlantUsersShowAmount += fetchIncrement
      const url = (
        `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/my_plant_users`
      )
      axios
        .get(url, {
          params: {
            maxResults: this.myPlantUsersShowAmount
          }
        })
        .then((resp) => {
          this.myPlantUsers = resp.data.users.map(this.parseBackendAllUsers)
          this.hasMoreMyPlantUsers = resp.data.may_have_more
          this.getAvailableShipToPartyNumbers()
        })
        .catch((error) => {
          this.$store.commit('setErrorMessage', error.response.data.message)
        })
        .finally(() => {
          this.isLoadingMyPlantUsers = false
        })
    },
    getApprovedUsers () {
      if (!this.isPermitted(userManagement.approvedUsers.list)) {
        return
      }
      this.isLoadingApprovedUsers = true
      const url = (
        `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/approved_users`
      )
      axios
        .get(url, {
          params: {
            cursor: this.approvedUsersCursor
          }
        })
        .then((resp) => {
          this.approvedUsers = [
            ...this.approvedUsers,
            ...resp.data.users.map(this.parseBackendApprovedUsers)
          ]
          this.approvedUsersCursor = resp.data.cursor
          this.hasMoreApprovedUsers = Boolean(this.approvedUsersCursor)
          this.getAvailableShipToPartyNumbers()
        })
        .catch((error) => {
          this.$store.commit('setErrorMessage', error.response.data.message)
        })
        .finally(() => {
          this.isLoadingApprovedUsers = false
        })
    },
    getQueriedAllUsers (userEmail) {
      this.isLoadingQueriedAllUsers = true
      const url = (
        `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/users`
      )
      axios
        .get(url, {
          params: {
            email: userEmail,
            cursor: this.queriedAllUsersCursor
          }
        })
        .then((resp) => {
          this.queriedAllUsers = resp.data.users.map(this.parseBackendAllUsers)
          this.queriedAllUsersCursor = resp.data.cursor
          this.hasMoreQueriedAllUsers = Boolean(this.queriedAllUsersCursor)
          this.getAvailableShipToPartyNumbers()
        })
        .catch((error) => {
          this.$store.commit('setErrorMessage', error.response.data.message)
        })
        .finally(() => {
          this.isLoadingQueriedAllUsers = false
        })
    },
    getQueriedMyPlantUsers (userEmail) {
      this.isLoadingQueriedMyPlantUsers = true
      this.queriedMyPlantUsersShowAmount += fetchIncrement
      const url = (
        `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/my_plant_users`
      )
      axios
        .get(url, {
          params: {
            email: userEmail,
            maxResults: this.queriedMyPlantUsersShowAmount
          }
        })
        .then((resp) => {
          this.queriedMyPlantUsers = [
            ...this.queriedMyPlantUsers,
            ...resp.data.users.map(this.parseBackendAllUsers)
          ]
          this.hasMoreQueriedMyPlantUsers = resp.data.may_have_more
          this.getAvailableShipToPartyNumbers()
        })
        .catch((error) => {
          this.$store.commit('setErrorMessage', error.response.data.message)
        })
        .finally(() => {
          this.isLoadingQueriedMyPlantUsers = false
        })
    },
    getAvailableShipToPartyNumbers () {
      if (
        this.availableShipToParties.length > 0 ||
        !this.isPermitted(userManagement.user.permissions.shipToPartyNumbers.update)
      ) {
        return
      }
      const url = (
        process.env.VUE_APP_USER_API_ENDPOINT +
        '/user_api/v1/tracking/uap/priorities/ship_to_party_codes'
      )
      axios.get(url)
        .then((resp) => {
          resp.data.ship_to_party_codes.forEach(
            element => this.availableShipToParties.push({ code: element })
          )
        })
        .catch((error) => {
          this.$store.commit('setErrorMessage', error.response.data.message)
        })
        .finally(() => {
          this.noDataShipToPartyNumbers = messages.getLabelNoShipToPartyNumbers()
        })
    }
  },
  mounted () {
    if (this.$store.state.auth.idToken) {
      this.getAllUsers()
      this.getMyPlantUsers()
      this.getApprovedUsers()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
