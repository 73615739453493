<template>
  <b-form
    :id="id"
    @submit="onSubmit"
    @reset="onReset"
    novalidate
  >
    <b-form-group
      label="Email"
      :label-for="id + '-field'"
      :state="fieldState"
    >
      <b-form-input
        type="email"
        :id="id + '-field'"
        :aria-describedby="id + '-field-help-block'"
        :aria-errormessage="id + '-field-error-message'"
        v-model="value"
        trim
        :state="fieldState"
      />
      <b-form-text
        :id="id + '-field-help-block'"
        class="non-small-text"
      >
        {{ messages.getLabelEmailDescriptionForAllUsers() }}
      </b-form-text>
      <b-form-invalid-feedback
        :id="id + '-field-error-message'"
        class="non-small-text"
      >
        {{ messages.getLabelEmailFormatError() }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group>
      <b-button type="submit" variant="primary">
        <i class="icon-magnifier" /> {{ messages.getActionView() }}
      </b-button>
      {{' '}}
      <b-button type="reset">
        <i class="icon-reset" /> {{ messages.getActionClear() }}
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { messages } from '@/utils/strings'
import * as VALIDATION_STATES from '@/constants/form_validation_states'

export default {
  name: 'EmailSearch',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      fieldState: VALIDATION_STATES.NEUTRAL,
      value: '',
      messages
    }
  },
  methods: {
    validateEmail () {
      if (this.value === '' || /^[^@]+@[^@]+$/.test(this.value)) {
        this.fieldState = VALIDATION_STATES.NEUTRAL
      } else {
        this.fieldState = VALIDATION_STATES.INVALID
      }
    },
    onSubmit (event) {
      if (this.value === '') {
        this.onReset(event)
        return
      }
      event.preventDefault()
      this.validateEmail()
      if (this.fieldState !== VALIDATION_STATES.INVALID) {
        this.$emit('submit', this.value)
      }
    },
    onReset (event) {
      event.preventDefault()
      this.value = ''
      this.validateEmail()
      this.$emit('submit', '')
    }
  }
}
</script>

<style scoped>
.non-small-text {
  font-size: inherit;
}
</style>
