<template>
  <b-form-tags
    v-bind:value="value"
    v-on:input="onInput"
    no-outer-focus
    class="border-0"
  >
    <template
      v-slot="{ tags, removeTag, addTag }"
    >
      <b-form-tag
        v-for="tag in tags"
        @remove="removeTag(tag)"
        :key="tag"
        :title="tag"
        :disabled="disabled"
        class="mb-2 mx-1"
      />
      <b-form-input
        v-if="!disabled"
        v-model="search"
        type="search"
        :placeholder="placeholder"
        class="mb-2"
      />
      <b-table
        v-if="!disabled"
        :items="unselected"
        :filter="search"
        :per-page="perPage"
        :current-page="currentPage"
        thead-class="d-none"
        table-class="border-bottom"
        show-empty
        :empty-text="emptyText"
        @filtered="onFiltered"
      >
        <template
          v-slot:cell(code)="data"
        >
          <b-button
            @click="addTag(data.item.code)"
            variant="link"
            class="text-decoration-none"
          >
            <i
              class="icon-circle-plus"
            />
          </b-button>
          {{ data.item.code }}
        </template>
      </b-table>
      <b-pagination
        v-if="!disabled"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        first-number
        last-number
        align="center"
      />
    </template>
  </b-form-tags>
</template>

<script>
export default {
  name: 'SearchableTableTags',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    unselected: {
      type: Array,
      default: () => []
    },
    emptyText: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    perPage: {
      type: Number,
      default: 4
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentPage: 1,
      search: ''
    }
  },
  computed: {
    totalRows () {
      return this.unselected.length
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>

<style scoped>
.badge {
  font-size: initial;
}
</style>
