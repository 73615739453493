<template>
  <span hidden></span>
</template>

<script>
/**
 * Emits a `value-changed` event when the supplied value changes.
 */
export default {
  name: 'ValueWatcher',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  watch: {
    value (value) {
      this.$emit('value-changed', value)
    }
  }
}
</script>
