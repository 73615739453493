<template>
  <div>
    <b-table
      :items="users"
      :fields="fields"
      :empty-text="noDataMessage"
      responsive
      show-empty
      table-class="border-bottom"
    >
      <template
        v-slot:cell(icon)
      >
        <b-avatar
          size="3rem"
        >
          <i
            class="icon-user"
          />
        </b-avatar>
      </template>
      <template
        v-slot:cell(permissions)="data"
      >
        <permissions-cell
          :user="data.item"
          :available-ship-to-parties="availableShipToParties"
          :no-data-ship-to-party-numbers="noDataShipToPartyNumbers"
        />
      </template>
      <template
        v-slot:cell(expires)="data"
      >
        <expiration-date-cell
          :is-editable="isPermitted(userManagement.user.expirationDate.update)"
          :user="data.item"
        />
      </template>
      <template
        v-if="isPermitted(userManagement.user.delete)"
        v-slot:cell(remove)="data"
      >
        <remove-user-cell :user="data.item" @remove="onUserRemove" />
      </template>
    </b-table>
    <p v-if="isLoading">
      <b-spinner :label="messages.getLabelLoading()"></b-spinner>
    </p>
    <b-button v-else-if="hasMore" @click="onUserLoadMore">
      {{ messages.getActionLoadMore() }}
    </b-button>
    <p v-else>
      ({{ messages.getLabelEndOfResults() }})
    </p>
  </div>
</template>

<script>
import { messages } from '@/utils/strings'
import { userManagement } from '@/constants/permitted-actions'
import ExpirationDateCell from './ExpirationDateCell'
import PermissionsCell from './PermissionsCell'
import RemoveUserCell from './RemoveUserCell'

export default {
  name: 'UserListTable',
  components: {
    ExpirationDateCell,
    PermissionsCell,
    RemoveUserCell
  },
  props: {
    users: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    hasMore: {
      type: Boolean,
      required: true
    },
    noDataShipToPartyNumbers: {
      type: String,
      required: true
    },
    availableShipToParties: {
      type: Array,
      default: () => []
    }
  },
  data () {
    const data = {
      fields: [
        { key: 'icon', label: '' },
        { key: 'userEmail', label: messages.getLabelEmail() },
        { key: 'lastLogin', label: messages.getFieldLastLogin() },
        { key: 'permissions', label: messages.getFieldPermissions() },
        { key: 'expires', label: messages.getFieldExpirationDate() }
      ],
      messages
    }
    if (this.isPermitted(userManagement.user.delete)) {
      data.fields.push({ key: 'remove', label: '' })
    }
    return data
  },
  computed: {
    noDataMessage () {
      return (
        this.isLoading
          ? messages.getLabelFetchingData()
          : messages.getLabelNoUsers()
      )
    }
  },
  created () {
    this.userManagement = userManagement
  },
  methods: {
    isPermitted (permittedAction, permissionIds) {
      return this.$store.getters.isPermitted(permittedAction, permissionIds)
    },
    onUserRemove (value) {
      this.$emit('remove', value)
    },
    onUserLoadMore () {
      this.$emit('load-more')
    }
  }
}
</script>

<style scoped lang="scss">
.icon-user {
  font-size: 20px;
}
</style>
